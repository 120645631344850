

@keyframes bounce {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(20%);
    }

}

@keyframes bounce2 {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(5%);
    }

}

@keyframes fade-in {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}


@keyframes height {
    from {
        height: 0%;
    }

    to {
        height: 100%;
    }

}


#blocks {
    position: relative;
    animation: bounce 1s infinite alternate-reverse;
}


.links {
    animation: bounce2 1s infinite alternate-reverse;
}

.name-feature  {
    animation: fade-in ease-in 2s 1;
}