/*--*CODE RESET*-------------------------------------------------------------*/

* {
	padding: 0;
	margin: 0;
	text-rendering: optimizeLegibility;
	font-family: poppins;
	text-decoration: none;
	list-style-type: none;
	scroll-behavior: smooth;
	/*border: red 1px solid;*/

}

a:visited {
	color: none;
}

a {
	color: var(--space_yellow);
}

body {


	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

:root {
	--hot_blue: #00e4e4;
	--space_yellow: #e5e5a9;
	--work_red: #ff4d5a;
	--stranger_red: rgb(94, 6, 6);
}


/*--*IMPORTS*-------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');






/*--*BODY*-------------------------------------------------------------*/

body {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: auto;
	text-align: center;
	font-family: 'monoton';
	height: 100vh;
	width: 100%;
	color: white;
}

.h1-style {
	display: flex;
	font-size: clamp(3rem, 8vw, 7rem);
	align-items: center;
	justify-content: center;
	margin-top: 7%;
	color: var(--space_yellow);
	z-index: 4;
	position: relative;
}

h2 {

	font-size: clamp(2.3rem, 50vw, 3rem);
	padding-top: 10%;
	color: rgb(255, 255, 255);
	font-weight: 400;
	font-family: 'monoton';
}

ul {
	width: 60%;
	text-align: left;
	padding: 10%;
	font-size: 1.1em;
}


li {

	margin: 0;
	/*padding: 36px 0 36px 84px;*/
	padding-left: 20%;
	padding-top: 3%;
	list-style: none;
	background-image: url("./img/check.png");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: clamp(20px, 4vw, 20px);
}

h5 {

	font-weight: 400;
	padding-bottom: 15%;
	width: 90% !important;
}



/*--*Music Player*-------------------------------------------------------------*/

audio {
	position: fixed;
	top: 1%;
	left: 1%;
	cursor: pointer;
	width: 230px;
	height: 30px;
	z-index: 10;
	border-radius: 20px;
}

audio::-webkit-media-controls-play-button {
	color: black;
}

audio::-webkit-media-controls-panel {
	background-color: white;
	background-image: url(./img/moon.png);
}

#music-message {
	position: fixed;
	top: 4.5%;
	left: 1%;
	cursor: pointer;
	width: 230px;
	height: 30px;
	z-index: 10;
	font-size: 8px;

}



/*--*HEADER*-------------------------------------------------------------*/

header {
	position: relative;
	height: 100vh;
	background-color: #0e0e0e;
	width: 100%;
}


/*--*HEADER > VIDEO*-------------------------------------------------------------*/

video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
}

#video-menu {

	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

}



/*--*HEADER > NAV*-------------------------------------------------------------*/

nav {
	position: absolute;
	z-index: 1;
	height: 8rem;
	background-color: transparent;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	top: 0;
	left: 0;

}

/*--*HEADER > NAV > LINKS*-------------------------------------------------------*/


.links {
	display: block;
	position: relative;
	z-index: 1;
	float: right;


	margin: 1%;
	margin-top: 7%;
	margin-right: 3%;
	padding: 0 1%;

	font-size: 1.5em;

	height: 150px;


	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	color: white;
	background-color: transparent;
	box-shadow: 1px 1px 5px 1px rgb(47, 47, 47);

}


.links a {
	color: white;
	font-size: 1.0rem;
	text-transform: uppercase;
	line-height: 20px;
	display: flex;
	justify-content: space-around;
	margin: 10% 0;
	padding: 0 5%;

}

.links a:first-child {

	margin: 1% 0;

}

.links a:hover {
	background-color: #000;
	color: #fff;
}

hr {
	margin-top: 5%;
}



/*--*HEADER > NAME*-------------------------------------------------------------*/

.name-feature {
	position: absolute;
	z-index: 2;
	font-weight: 500;
	font-size: 7rem;
	font-size: clamp(2.7rem, 10vw, 7rem);
	top: 20%;
	width: 100%;
}


.first-name {
	color: red;
	font-weight: 400;
	font-family: 'monoton';
}

.last-name {
	position: relative;
	color: white;
	margin-top: -.6em;
	margin-left: .8em;
	font-family: 'monoton';
}

.last-name-two {
	position: relative;
	color: rgba(255, 255, 255, 0.533);
	margin-top: -1.3em;
	margin-left: 1em;
	font-family: 'monoton';
}


/*--*HEADER > CAREER TITLE*-------------------------------------------------------------*/

.developer {
	color: blanchedalmond;
	font-size: 1.5rem;
	font-size: clamp(.5rem, 3vw, 1.5rem);
	margin-top: -2%;
	margin-left: 15%;
	text-transform: uppercase;
	opacity: .8;
	font-family: 'Lato', sans-serif;
	letter-spacing: 1px;
}


/*--*HEADER > SOCIAL MEDIA*-------------------------------------------------------------*/

.social-media {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	position: absolute;

	top: 75%;
	left: 43%;
	padding: 1%;
	padding-top: 3%;
	width: 200px;

}

.mygit,
.linkedin,
.resume {
	filter: invert(100%);
	padding: 30%;

}

.social-media img {
	width: clamp(50px, 100%, 70px);
	height: 100%;
}




/*--*HEADER > NAV > TOGGLE*-------------------------------------------------------*/

.toggle--button-box {
	position: fixed;
	top: 0%;
	right: 4.7%;
	cursor: pointer;
	margin-bottom: 10%;



}

/*--*SECTION 1: ABOUT *-------------------------------------------------------------*/


.about-section {
	background-color: #0e0e0e;
	height: clamp(55rem, 100%, 110rem);
	position: relative;
}




/*
#more p {

	background-color: #fff;
	font-size: 1.3em;
	margin-top: 100%;
	border-top: 1px #333 solid;
	height: 200px;
	color: black;
}
*/

#moreBtn {
	width: 100%;
	background-color: #fff;
	color: #333;
	border: none;
	font-size: 1rem;
}

#moreBtn:hover {
	background-color: var(--space_yellow);
	color: #000;
}

#quote {
	text-align: center;
	font-size: clamp(1.0em, 5vw, 1.5em);
	font-style: italic;
}

.summary {
	font-size: clamp(.5rem, 4vw, 1.5rem);
	width: 85%;
	position: relative;
	z-index: 4;
	margin: 0 auto;
	padding: 4%;
}

.summary p {
	padding: 2%;
	text-align: left;
}

h1 {
	font-family: 'monoton';
}

p.hello {
	text-align: center;
	font-size: 2em;
	text-transform: uppercase;
}


.summary button {
	padding: 4%;
	margin: 1%;
	width: 30%;
	background-color: transparent;
	font-size: .7em;
	font-weight: bolder;
	font-family: poppins;
	border: yellow solid 3px;
	text-transform: uppercase;
	cursor: pointer;
	color: var(--space_yellow);

}



.summary button:hover {
	background-color: var(--space_yellow);
	color: black;
	font-weight: bolder;
}





/*--------------------------------------*/


/*TOOLS SECTION*/

section.skills-section {
	height: clamp(53rem, 100%, 83rem);
	background-color: #0e0e0e;
	position: relative;
	background-image: url(./img/stars.png);
	width: 100%;
	border-top: grey solid 1px;
	padding-bottom: 7%;

}


.wordpress,
.git {
	filter: invert(100%);
	color: black;
}

.skill_blocks {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	position: relative;
	width: 60%;
	place-items: center;
	margin: 0 auto;
	margin-top: 5%;

}

#blocks {
	height: 200px;
	width: 100%;
	text-align: center;
	place-items: center;
	padding: 7%;
}

#blocks {
	position: relative;

}

#blocks img {

	width: 100px;

}

/*--*SECTION 2*-------------------------------------------------------------*/

/* PORTFOLIO SHOWCASE*/

.portfolio-section button {
	padding: 4%;
	margin: 1%;
	width: 30%;
	background-color: transparent;
	font-size: .7em;
	font-weight: bolder;
	font-family: poppins;
	border: yellow solid 3px;
	text-transform: uppercase;

	color: var(--space_yellow);

}

.portfolio-section button:hover {


	background-color: var(--space_yellow);
	color: black !important;
	font-weight: bolder;

}




#works-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2%;
	background: #000 url("./img/stars.png") no-repeat center center;
	background-size: cover;
}



#works-described {
	display: flex;
	flex-direction: column;
	margin-top: 100%;
	align-items: center;
	justify-content: center;
	padding: 2%;

}



section.portfolio-section {
	height: 100%;
	background-color: #000;
	color: #2d2d00;
	background-image: url(./img/stars.png);

}



.articles {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr;


}

article {

	max-width: clamp(70%, 100%, 70%);
	margin: 10% auto;
	border-radius: 10px;
	box-shadow: 1px 5px 20px 10px var(--space_yellow);



}


#apps,
#websites,
#designs {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	height: 100%;
	border-bottom: 1px dotted white;

}



#apps h3,
#websites h3,
#designs h3 {

	font-size: clamp(1rem, 4vw, 1rem);

}


.portfolio-section li,
h5 {
	font-size: clamp(.5rem, 4vw, .9rem);
	width: 70%;


}


/*APPS*/

article.one_box {
	background: #fff url("./portfolio/TipCalc.png") no-repeat top center;
	background-size: contain;
	height: 85%;


}


article.two_box {
	background: #fff url("./portfolio/counter.png") no-repeat 100% top;
	background-size: contain;
	height: 85%;

}


article.three_box {
	background: #fff url("./portfolio/bmr.png") no-repeat top center;
	background-size: contain;
	height: 85%;

}

article.four_box {
	/*API PROJECT BLANK*/
	background: #fff url("./portfolio/quotes.png") no-repeat top center;
	background-size: contain;
	height: 85%;

}


/*WEBSITES*/


article.five_box {
	background: #fff url("./portfolio/yoga.png") no-repeat top center;
	background-size: contain;


}


article.six_box {
	background: #fff url("./portfolio/coffee.png") no-repeat top center;
	background-size: contain;

}


article.seven_box {
	background: #fff url("./portfolio/Fit.png") no-repeat top center;
	background-size: contain;

}


article.eight_box {
	background: #fff url("./portfolio/bakery-concept.png") no-repeat top center;
	background-size: contain;


}


/*Logos*/

article.nine_box {
	background: #fff url("./portfolio/Love\ Cones.png") no-repeat top center;
	background-size: contain;
	padding: 10 0%;


}

article.ten_box {
	background: #fff url("./portfolio/IAM\ Tower.png") no-repeat top center;
	background-size: contain;
	padding: 5%;
}








/*--*FOOTER*-------------------------------------------------------------*/

footer {
	background-color: #0e0e0e;
	height: 100%;
	position: relative;
	width: 100%;

}


.contact-form {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto;
	height: 450px;
	width: clamp(150px, 70%, 500px);
	background-color: #343333;
	margin: 0 auto;
	padding: 4%;
	font-weight: 300;
	box-shadow: 1px 2px 2px 2px black;
	border-radius: 10px;
}


input[placeholder] {
	font-size: 1.0em;
	color: black;
}

input[type="text"] {
	padding: 2%;

	height: 30px;
	width: 95%;
	background-color: #fff;
	color: black;

	font-size: clamp(1.0rem, 4vw, 2.0rem);
}

input[type="email"] {
	padding: 2%;
	height: 30px;
	width: 95%;
	margin: 3% 0;
	background-color: #fff;
	color: black;
	font-size: clamp(1.0rem, 4vw, 2.0rem);
}

.submit-btn {
	padding: 2%;
	width: 40%;
	background-color: var(--space_yellow);

	font-size: clamp(.5rem, 4vw, 1.5rem);

	border: none;
	text-transform: uppercase;
	margin-top: 2%;
	cursor: pointer;
	color: #0e0e0e;
	font-family: 'monoton';
}


textarea {
	height: 200px;
	width: 100%;
	background-color: #fff;
	color: black;
	font-size: clamp(1.0rem, 4vw, 2.0rem);
	text-align: center;
}

.copyright {
	padding: 2%;
	word-spacing: 1px;
	letter-spacing: 2px;
	color: var(--space_yellow);

}


.arrow {
	position: fixed;
	top: 94%;
	left: 94%;
	filter: invert(100%);
	width: 3%;
	cursor: pointer;
}


/* ===============MEDIA QUERIES================== */


@media only screen and (max-width : 600px) {

	#music-message {

		display: none;
	}

	audio {
		display: none;
	}

	.links {

		margin: 1%;
		margin-top: 15%;
		margin-right: 3%;
		padding: 0 1%;

		font-size: 1.5em;
		height: 150px;


	}



	.articles {

		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr;

	}

	.skill_blocks {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;

		width: 90%;
		margin: 0 auto;
		margin-top: 5%;
	}

	#blocks {
		height: 150px;
		width: 100%;
		text-align: center;
		place-items: center;
		padding: 7%;
	}

	#apps,
	#websites,
	#designs {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		margin: 0 auto;
	}




	article.one_box {

		height: 90%;


	}


	article.two_box {

		height: 90%;

	}


	article.three_box {

		height: 90%;

	}


	article.nine_box {

		height: 600px;
		padding: 5%;

	}

	article.ten_box {

		height: 370px;
		padding: 5%;

	}



	section.portfolio-section {

		height: 100%;

	}

	#blocks img {

		width: 50px;

	}


	.social-media {

		top: 90%;
		left: 30%;
		padding: 1%;
		width: 200px
	}

	audio {
		position: fixed;
		top: 1%;
		left: 1%;
		width: 3%;
		width: 200px;
		height: 25px;

	}

	footer {
		background-color: #0e0e0e;
		height: 100vh;
	}

	.copyright {

		padding: 5%;
		word-spacing: 1px;
		letter-spacing: 2px;
		color: var(--space_yellow);
		font-size: 12px;
	}

	article {
		display: grid;
		grid-template-rows: auto;
		height: 850px;
	}



}